import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
// import Projects from "../components/Projects"
import HeaderLine from "../components/HeaderLine"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import Footer from "../components/Footer"
import Image from "gatsby-image"

import { RiCalendarCheckFill } from "react-icons/ri"
import { RiMapPinFill } from "react-icons/ri"
// import { AiFillHome } from "react-icons/ai"
import { RiLayoutGridFill } from "react-icons/ri"

// import project1 from "../assets/projects/project-1.jpg"
// import project2 from "../assets/projects/project-3.jpg"
// import project3 from "../assets/projects/project-2.jpg"
// import project4 from "../assets/projects/project-2.jpg"
// import project5 from "../assets/projects/project-4.jpg"
// import project5big from "../assets/projects/project-4-big.jpg"
// import project6 from "../assets/projects/project-5.jpg"

// const images = [
//   project1,
//   project2,
//   project3,
//   project4,
//   project5big,
//   project5,
//   project6,
// ]

const ProjectTemplate = ({
  data: {
    project: {
      id,
      name,
      address,
      slug,
      size,
      date_finish,
      date_continue,
      image_small,
      image_big,
      description_long,
    },
  },
  pageContext,
  location,
}) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  console.log("------", date_continue)

  // Example of dynamically using location prop as a crumbLabel
  // NOTE: this code will not work for every use case, and is only an example
  // const customCrumbLabel = location.pathname.toLowerCase().replace("-", " ")
  return (
    <Layout>
      <HeaderLine />
      <div className="container mx-auto max-w-screen-xl py-8 pb-2 container-pad">
        <Breadcrumb
          crumbs={crumbs}
          crumbSeparator=" / "
          // crumbLabel={customCrumbLabel}
          crumbLabel={name}
          // className="breadcrumb__link__active"
        />
        {/* <Breadcrumb location={location} crumbLabel="НАШИ ПРОЕКТЫ" /> */}
      </div>

      {/* <div className="container mx-auto max-w-screen-xl flex-none text-center text-3xl sm:text-5xl leading-none py-4 pb-8 container-pad">
        ПРОЕКТ
      </div> */}

      <div className="container container-pad mx-auto max-w-screen-xl mb-8 pt-8">
        <div className="flex flex-wrap">
          <div className="project__text pb-6 pr-3 tracking-wider w-full lg:flex-1">
            <div className="text-2xl font-bold sm:text-base md:text-lg lg:text-xl xl:text-2xl pb-8 leading-6 lg:leading-8">
              {name}
            </div>

            <div className="flex items-center pb-3">
              <RiCalendarCheckFill className="inline text-2xl mr-3 text-sand" />

              {date_continue ? (
                <div className="font-bold">в работе</div>
              ) : (
                <div
                  className="font-bold"
                  style={{ textTransform: "capitalize" }}
                >
                  {date_finish}
                </div>
              )}
            </div>

            {address && (
              <div className="flex items-center pb-3">
                <RiMapPinFill className="flex-none text-2xl mr-3 text-sand" />
                <div className="leading-4">{address}</div>
              </div>
            )}

            {size ? (
              <div className="flex items-center pb-8">
                <RiLayoutGridFill className="inline text-2xl mr-3 text-sand" />
                <div>{size}</div>
              </div>
            ) : (
              ""
            )}

            <div className="text-base pb-3 lg:block md:text-sm lg:text-sm xl:text-base leading-5">
              {description_long}
            </div>
          </div>
          {/* <Image fixed={image_big.childImageSharp.fixed} className="" /> */}
          <Image
            fixed={image_big.childImageSharp.fixed}
            className="mx-auto w-full"
          />
        </div>
      </div>

      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query GetSingleProject($slug: String) {
    project: strapiProjects(slug: { eq: $slug }) {
      id
      name
      strapiId
      slug
      size
      address
      date_finish(formatString: "YYYY, MMMM", locale: "ru")
      date_continue
      description_short
      description_long
      image_big {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }

          fixed(width: 800, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`

export default ProjectTemplate
