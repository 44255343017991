import React from "react"

const HeaderLine = () => {
  return (
    <div className="header-line">
      <hr></hr>
    </div>
  )
}

export default HeaderLine
